var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8 px-8 pt-3" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center textDark--text mb-10 cursor--pointer",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [
        _c("v-icon", { attrs: { color: "textDark" } }, [
          _vm._v("mdi-arrow-left mr-4"),
        ]),
        _c("div", { staticClass: "mew-heading-2" }, [
          _vm._v("Select provider"),
        ]),
      ],
      1
    ),
    !_vm.hideMoonpay
      ? _c("div", { staticClass: "section-block pa-5 mb-6" }, [
          _c("img", {
            staticClass: "provider-logo",
            attrs: {
              src: require("@/modules/buy-sell/assets/moonpay-logo.svg"),
              height: "18",
            },
          }),
          _c("div", { staticClass: "mb-3" }, [
            _c(
              "div",
              { staticClass: "d-flex mb-1 align-center justify-space-between" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mew-heading-3 textDark--text" },
                  [
                    _vm._v(" " + _vm._s(_vm.buyObj.cryptoToFiat) + " "),
                    _c("span", { staticClass: "mew-heading-3 pl-1" }, [
                      _vm._v(_vm._s(_vm.selectedCryptoName)),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("div", { staticClass: "mr-1 textDark--text" }, [
                  _vm._v("≈ " + _vm._s(_vm.buyObj.plusFeeF)),
                ]),
                _c("mew-tooltip", {
                  staticStyle: { height: "21px" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "contentSlot",
                        fn: function () {
                          return [
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.buyObj.includesFeeText) + " "
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(_vm.buyObj.networkFeeText) + " "
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(" " + _vm._s(_vm.buyObj.dailyLimit) + " "),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(_vm.buyObj.monthlyLimit) + " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3998068884
                  ),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "d-flex align-center mb-1" }, [
            _c("img", {
              staticClass: "mr-2",
              attrs: {
                src: require("@/assets/images/icons/moonpay/icon-visa.svg"),
                alt: "Visa",
                height: "24",
              },
            }),
            _c("img", {
              staticClass: "mr-2",
              attrs: {
                src: require("@/assets/images/icons/moonpay/icon-master.svg"),
                alt: "Master",
                height: "24",
              },
            }),
            _c("img", {
              staticClass: "mr-2",
              attrs: {
                src: require("@/assets/images/icons/moonpay/icon-apple-pay.svg"),
                alt: "Master",
                height: "24",
              },
            }),
            _vm.isEUR
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/icons/moonpay/icon-bank.svg"),
                    alt: "Bank",
                    height: "24",
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mew-label mb-5" }, [
            _vm._v(" " + _vm._s(_vm.paymentOptionString) + " "),
          ]),
          _c(
            "div",
            [
              _c("mew-button", {
                attrs: {
                  "btn-size": "large",
                  "btn-style": "light",
                  "color-theme": "buttonGrayLight",
                  "has-full-width": "",
                  "is-valid-address-func": _vm.isValidToAddress,
                  title: _vm.moonpayBtnTitle,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.buy.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.hideSimplex
      ? _c("div", { staticClass: "section-block pa-5" }, [
          !_vm.loading
            ? _c("div", { staticClass: "mb-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex mb-1 align-center justify-space-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mew-heading-3 textDark--text" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.simplexQuote.crypto_amount) + " "
                        ),
                        _c("span", { staticClass: "mew-heading-3 pl-1" }, [
                          _vm._v(_vm._s(_vm.selectedCryptoName)),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("div", { staticClass: "mr-1 textDark--text" }, [
                      _vm._v(
                        " ≈ " +
                          _vm._s(
                            _vm.currencyFormatter(
                              _vm.simplexQuote.fiat_base_amount
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("mew-tooltip", {
                      staticStyle: { height: "21px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "contentSlot",
                            fn: function () {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.buyObj.includesFeeText) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.buyObj.networkFeeText) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.buyObj.dailyLimit) + " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.buyObj.monthlyLimit) + " "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3998068884
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mb-1",
                    attrs: { type: "heading" },
                  }),
                  _c("v-skeleton-loader", {
                    attrs: { "max-width": "200px", type: "heading" },
                  }),
                ],
                1
              ),
          _vm._m(0),
          _c("div", { staticClass: "mew-label mb-5" }, [
            _vm._v("Visa, Mastercard"),
          ]),
          _c(
            "div",
            [
              _c("mew-button", {
                attrs: {
                  "btn-size": "large",
                  "btn-style": "light",
                  "color-theme": "buttonGrayLight",
                  "has-full-width": "",
                  title: _vm.simplexBtnTitle,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openSimplex.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-center justify-space-between" },
      [
        _c("div", { staticClass: "d-flex align-start mb-1" }, [
          _c("img", {
            staticClass: "mr-2",
            attrs: {
              src: require("@/assets/images/icons/moonpay/icon-visa.svg"),
              alt: "Visa",
              height: "24",
            },
          }),
          _c("img", {
            staticClass: "mr-2",
            attrs: {
              src: require("@/assets/images/icons/moonpay/icon-master.svg"),
              alt: "Master",
              height: "24",
            },
          }),
        ]),
        _c("img", {
          staticClass: "provider-logo",
          attrs: {
            src: require("@/assets/images/icons/icon-simplex.svg"),
            alt: "simplex",
            height: "28",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }